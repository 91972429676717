

.hero {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-size: 90%; /* Biztosítja, hogy a háttérkép kitöltse a helyet */
  background-position: center; /* Középre helyezi a képet */
  min-height: 100vh; /* Ez teszi teljes képernyősé a hero szekciót */
  width: 100%; /* Biztosítja, hogy a szélessége is teljes legyen */
  flex-direction: column;
}

.bg-image {
  background-image: url('../../assets/hero_background.png');
}

.hero-content {
  flex: 1;
  margin-top: 80px;
}

/* Növelje meg a h1 szöveg méretét */
.hero-content h1 {
  color: rgb(2, 4, 5);
  font-size: 4em; /* 4-szeres méret */
  margin-bottom: 20px;
  text-align: center; /* Középre igazítás */
}

.hero-content h3 {
  color: rgb(2, 4, 5);
  font-size: 2em; /* 4-szeres méret */
  margin-bottom: 20px;
  text-align: center; /* Középre igazítás */
}

.hero-content button {
  color: rgb(2, 4, 5);
  font-size: 2em; /* Növelt méret */
  margin-bottom: 20px;
  padding: 10px 20px; /* Padding a jobb kinézet érdekében */
  border: none; /* Eltávolítja a keretet */
  border-radius: 5px; /* Kerekített sarkok */
  background-color: rgba(255, 255, 255, 0.5); /* Áttetsző fehér háttér */
  cursor: pointer; /* Mutatóujj kurzor hoverkor */
  transition: background-color 0.3s; /* Háttérszín animáció hoverkor */

}


a { 
  color: inherit; 
} 

.buttonpos {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  margin-bottom: -40px;
}

.hero-product-text{
  margin: 5px;
  font-size: 22px;
}

.hero-content button:hover {
  background-color: rgba(255, 255, 255, 0.7); /* Sötétebb áttetsző háttér hoverkor */
}
/* Új stílusok a központi szöveghez és a képekhez */
.hero-text {
  text-align: center;
  margin-bottom: 20px;
}

.hero-products {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.hero-product {
  width: 450px; /* 3-szoros méret */
  height: 450px;
  border: 2px solid black;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  background-size: cover;
  background-position: center;
  background-color: rgba(255, 255, 255, 0.8); /* Áttetsző fehér háttér */
  cursor: pointer;
}


.user-details {
  max-width: 600px;
  margin: 20px auto;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.user-details h3 {
  color: #333;
  font-weight: bold;
  margin-bottom: 10px;
}

.user-details ul {
  list-style: none;
  padding: 0;
}

.user-details li {
  margin-bottom: 5px;
  font-size: 22px;
  color: #555;
}

.user-details li::before {
  content: "• ";
  color: #167cac;
  font-weight: bold;
}

.calories-text {
  color: #167cac; 
}

/* Mobil */

@media (max-width: 768px) {
 
  
  .hero-content h1 {
    margin-bottom: 0px;
  }
   
  .hero-content h1, .hero-content h3 {
    width: 80vw;
    background-color: transparent;
    backdrop-filter:blur(4px); 
    padding:  0 10px;

  }
  


  .hero-products {
    flex-direction: column; 
    gap: 15px; 
  }

  .hero-product {
    width: 80vw; /* A képernyő szélességének 80%-a */
    height: 40vh; /* A képernyő magasságának 40%-a */
  }

  .hero-product-text {
    font-size: 18px; 
  }

  .buttonpos {
    margin-top: 20px; 
  }

  .user-details, .user-details ul, .user-details li {
    width: 100%; 
    padding: 0 10px; 
    text-align: center; 
  }
}

/* Tablet nézet */
@media (min-width: 769px) and (max-width: 1024px) {
  .hero-product {
    width: 45vw; 
    height: 30vh; 
  }

  .hero-product-text {
    font-size: 20px; 
  }
}