@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}


html {
  scroll-behavior: smooth;
}

body {
  max-width: vw;
  overflow-x: hidden;
}

h2 {
  font-size: 24px;
}

nav {
  height: 80px;
 position: relative;
 box-shadow: 0 2px 2px 0 rgba(0,0,0,0.05);
 background-color: #fff;
}

img {
  width: 100%;
}

p {
  line-height: 1.5;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

.blue{
  color: #167cac;
}

.underlined {
  text-decoration: underline;
}

.success-popup {
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  background: #dff0d8;
  color: black;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 100;
}

/* Sikeres üzenet stílusa */
.success-popup-checkout {
  position: fixed;
  top: 0;
  left: 10%;
  width: 80%;
  padding: 20px;
  box-sizing: border-box;
  background-color: #4CAF50; /* Zöld háttér */
  color: white; /* Fehér szöveg */
  text-align: center;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000; /* Biztosítja, hogy az üzenet a legfelső rétegben jelenjen meg */
  animation: slideDown 0.5s ease-out forwards;
}

/* Sikeres üzenet animációja */
@keyframes slideDown {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

.black {
  color: black;
}

.grey {
  color: rgba(92, 89, 89, 0.856);
}

.section__title {
  text-align: center; 
  font-size: 2em; 
  margin-top: 15px;
  margin-bottom: 20px; 
  color: #167cac; 
  font-weight: bold; 
  letter-spacing: 1px; 
}


li {
  list-style-type: none;
}

button{
  cursor: pointer;
}

button:active {
  transform: translateY(1px);
}


.smaller__h3 {
  font-size: 14px;
  padding-bottom: 5px;
  margin-top: 5px;
}

.btn {
  background-color: #167cac;
  color: white;
  padding: 12px 24px;
  font-size: 20px;
  border-radius: 4px;
  border: none;
}

.btn__success {
  background-color: green;
  color: white;
  padding: 8px;
  font-size: 12px;
  border-radius: 4px;
  border: none;
  margin-left: 10px;
  margin-top: 3px;
}

.nav__container {
  width: 100%;
  max-width: 1200px;
  padding: 0 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}


.nav__link {
  text-decoration: none;
  font-size: 20px;
  font-weight: bold;
  padding: 8px;
  color: black;
}

.nav__list {
  margin-left: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #167cac;
  font-size: 20px;
  position: relative;
  margin-left: 8px;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropbtn {
  background-color: transparent;
  text-decoration: none;
  font-size: 20px;
  font-weight: bold;
  padding: 8px;
  color: black;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9; /* Háttérszín */
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}


.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  width: 100%;
}

.dropdown-content a:hover {background-color: #ededff;}

.dropdown:hover .dropdown-content {display: block;}

.cart__length {
  background-color: red;
  color: white;
  width: 18px;
  height: 18px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: absolute;
  bottom: 4px;
  right: -4px;
  pointer-events: none;
}

.btn__menu {
  background-color: transparent;
  font-size: 32px;
  border: none;
  color: #167cac;
  display:none;
}

.menu__backdrop {
  position: absolute;
  background-color: #242424;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  transition: all 300ms ease;
  opacity: 0;
  transform: translate(100%);
}

.menu--open {
  max-height: 100vh;
  overflow: hidden;
}

.menu--open .menu__backdrop {
  visibility: visible;
  opacity: 1;
  transform: translate(0);
}

.menu__links {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.menu__list {
  padding: 32px 0;
}

.menu__link {
  color: white;
  font-size: 48px;
}

.btn__menu--close {
  position: absolute;
  top: 10px;
  right: 20px;
  padding: 8px;
  color: white;
}

.drp__container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 10px;
}

.drp__container button:hover {
  background-color: #ededff;
}

/* USER AUTH */

.row__container {
  display: flex;
  justify-content:space-between;
  align-items: start;
  flex-direction: row;
  margin: 10px;
}

.row__btn {
  margin-right: 10px;
  background-color: transparent;
  border: none;
}

.row__btn:hover {
  color: #606266;
  transition: all 300ms ease
}

.smaller__icon {
  font-size: 16px;
  margin-right: 10px;
}

/* Bejelentkezési űrlap konténer */
.userauth-form-container {
  display: flex;
  justify-content: start;
  align-items: center;
  height: 70vh;
  flex-direction: column;
}




.account__title {
  color: #167cac;
  font-size: 40px;
  margin-bottom: 24px;
  padding-bottom: 8px;
}

.border__bottom {
  border-bottom: 2px solid hsla(0,0%,52.2%,.1)!important;
}

.account__container {
  width: 100%;
  max-width: 1200px; 
  margin: auto; 
  padding: 20px; 
}

.detail__field {
  width: 100%;
  padding-right: 40px;
  display: flex;
  flex-direction: column;
}

.detail__input {
  min-width: 240px;
  width: 100%;
}

.detail__pw-strength {
  margin-top: -8px;
}

.Password {
  max-width: 400px;
  margin: 0 auto;
}

.Password__strength-meter {
  position: relative;
  height: 3px;
  background: #ddd;
  margin: 12px auto 8px;
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  margin-left: -360px;
  margin-right: 424px;
}



.Password__strength-meter--fill {
  background: transparent;
  height: inherit;
  position: absolute;
  width: 0;
  border-radius: inherit;
  transition: width .5s ease-in-out, background .25s;
}

.Password__strength-meter:before {
  left: 18%;
}

.Password__strength-meter:after {
  right: 18%;
}

.Password__strength-meter:after, .Password__strength-meter:before {
  content: "";
  height: inherit;
  background: transparent;
  display: block;
  border-color: #fff;
  border-style: solid;
  border-width: 0 5px;
  position: absolute;
  width: 20%;
  z-index: 10;
}

.form__text {
  font-size: 12px;
  color: rgba(14,19,24,.7);
}

.el-input {
  position: relative;
  font-size: 14px;
  display: inline-block;
  width: 30%;
}

.el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color .2s cubic-bezier(.645,.045,.355,1);
  width: 100%;
}

.el-input__prefix, .el-input__suffix {
  -webkit-transition: all .3s;
  color: #c0c4cc;
}
.el-input__suffix {
  right: 5px;
  transition: all .3s;
  pointer-events: none;
}
.el-input__prefix, .el-input__suffix {
  position: absolute;
  top: 0;
  text-align: center;
  height: 100%;
}

.detail__field--pw {
  max-width: 360px;
}

.container__detail {
  display: flex;
  width: 80%;
}

.container__modify {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
 
}


.field__grid {
  width: 100%;
  display: grid;
  grid-row-gap: 16px;
}

.detail {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  padding-bottom: 24px;
}

.detail__title {
  font-weight: 800 !important;
}

.detail__title {
  font-weight: 400;
}

.btns__detail {
  display: flex;
}

.btn__detail, .btns__detail {
  align-self: flex-end;
}

.btn__text {
  opacity: 1;
  display: flex;
  align-items: center;
  vertical-align: middle;
  grid-gap: 6px;
  gap: 6px;
}

.btn__edit {
  align-items: center;
  position: relative;
  border-radius: 4px;
  cursor: pointer;
  display: inline-flex;
  font-family: inherit;
  font-size: 14px;
  justify-content: center;
  letter-spacing: .03em;
  line-height: 1;
  opacity: 1;
  outline: 0;
  transition: background-color .2s ease 0s,opacity .2s ease 0s;
  height: 40px;
  padding: 0 24px;
  border: none;
}

.btn__edit:hover {
  background-color: rgba(202, 194, 194, 0.425);
  transition: all 300ms ease;
}




.account__skeleton--title {
  height: 20px;
  width: 70%;
}

.account__skeleton--detail {
  height: 15px;
  width: 90%;
}


/* Űrlap stílusa */

.h3__message {
  font-size: 16px;
  text-align: start;
  margin-left: 15px;
  color: rgba(92, 89, 89, 0.856);
}

.ubtn {
  background-color: transparent;
  color: #303133;
  padding: 5px;
  font-size: 16px;
  border-radius: 4px;
  border: none;
  margin: 10px;
}

.ubtn:hover{
  background-color:#ededff;
  transition: all 300ms ease;
}

.userauth-form {
  width: 350px;
  padding: 20px;
  background-color: #fff;
}

.userauth-form input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.userauth__input--readonly {
  font-weight: bold;
  border: none !important;
  width: 100%;
  padding: 0px !important;
  padding-bottom: 10px !important;
  margin-left: 0px;
  text-decoration: none !important;
}



.userauth-form button {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  border: none;
  border-radius: 4px;
  background-color: #167cac;
  color: white;
  cursor: pointer;
}



.btn__cancel {
  padding: 10px;
  margin-top: 10px;
  border: none;
  border-radius: 4px;
  background-color: #8f9091;
  color: white;
  cursor: pointer;
  margin-left: 20px;
  font-size: large;
}

.btn__modify{
  padding: 10px;
  margin-top: 10px;
  border: none;
  border-radius: 4px;
  background-color: #167cac;
  color: white;
  cursor: pointer;
  margin-left: 20px;
  font-size: large;
}

.userauth-form button:hover {
  background-color: #1649a7;
  transition: all 300ms ease;
}

.userauth-form p {
  color: red;
  font-size: 0.8rem;
}

.userauth-success {
  color: green;
  font-size: 1.2em;
  text-align: center;
}

.userauth-links {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.userauth-link {
  color: #007bff;
  text-decoration: none;
}

.userauth-link button{
  text-decoration: none;
}

.userauth-link:hover {
  text-decoration: none;
}

.user-icon {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px; 
  background: #167cac; 
  color: white; 
  border: none; 
}

.verify__title--container {
  margin-left: 20px;
}


.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-container {
  background: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: row;
  width: 80%; 
  max-width: 800px;
  max-height: 700px; 
}

.modal-container > * {
  flex: 1;
}

.modal-image {
  background-image: url('./assets/modalpic.jpg'); 
  background-size: cover;
  background-position: center;
  border-radius: 5px 0 0 5px; 
}

.modal-content {
  padding: 20px; 
}





/* LANDING  */

#landing {
  background: rgba(198, 197, 202, 0.452);
}

header {
  height: calc(100vh - 20px);
}

.header__container {
  width: 100%;
  max-width: 720px;
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.header__description {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header__img--wrapper {
  padding: 20px;
  z-index: 50;
}

.container {
  padding: 56px 0;
}

.container__footer {
  padding: 26px 0;
}

.container__buttons {
  padding: 56px 0;
}

.plans__container {
  display: flex;
  flex-direction: row;
  margin: 20px;
  justify-content: space-evenly;
}

.plans__container--cart {
  display: flex;
  flex-direction: row;
  margin: 20px;
  justify-content: space-between;
  width: 50%;
}

.row {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 24px;
}

.row__column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.no-cursor {
  cursor: not-allowed;
}

/* HIGHLIGHST */

#highlights {
  position: relative;
  max-width: 100vw;
}

#highlights:after,
#highlights:before {
  background: url("./assets/wave.svg");
  content: "";
  position: absolute;
  width: 101vw;
  height: 12vw;
  left: 0;
  z-index: 1;
  background-size: cover;
  background-repeat: no-repeat;
}

#highlights:before {
  transform: translateY(-100%);
  top: 1px;
}

#highlights:after {
  transform: translateY(100%) rotate(180deg);
  bottom: 1px;
}

.highlight__wrapper {
  display: flex;
  flex-wrap: wrap;
}

.highlight {
  padding: 0 24px;
  width: calc(100% / 3);
  display: flex;
  align-items: center;
  flex-direction: column;
}

.highlight__para {
  text-align: center;
  min-width: 280px;
}

.highlight__img {
  border: 2px solid #1696ac;
  color: #167cac;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  font-size: 32px;
  margin-bottom: 24px;
}

.highlight__subtitle {
  font-size: 20px;
  margin-bottom: 16px;
  text-align: center;
}


/*FEATURES*/

#features {
  padding-top: 8vw;
  background: rgba(198, 197, 202, 0.452);
}

.plans {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -24px;
}

.plan {
  width: 25%;
  padding: 24px;
  transition: all 300ms ease;
}

.plan:hover {
  transform: translateY(-4px);
}

.plan__img {
  max-height: 320px;
  max-width: 250px;
  transition: all 300ms ease;
}

.plan__img--skeleton {
  width: 100%;
  max-width: 250px;
  height: 320px;
  background-color: rgb(204, 204, 204);
  margin-bottom: 8px;
}

.skeleton {
  background-color: rgb(227,227,227);
  margin-bottom: 8px;
}

.plan__title--skeleton {
  height: 21px;
  width: 100%;
}

.plan__rating--skeleton {
  width: 100px;
  height: 19px;
}

.plan__price--skeleton {
  width: 200px;
  height: 19px;
}

.plan__link {
  color: #242424;
}

.plan__img--wrapper {
  margin-bottom: 8px;
  border-radius: 4px;
  overflow: hidden;
  display: flex;
}

.plan__title {
  font-size: 20px;
  margin-bottom: 8px;
}

.plan__title--link {
  color: #242424
}

.plan__ratings {
  margin-bottom: 8px;
  color: #f1e20e;
}

.plan__price--normal {
  text-decoration: line-through;
  padding-right: 6px;
  color: #bfbfbf;
}

/* EXPLORE */

#explore {
  background-color: rgba(198, 197, 202, 0.452);
}

/* FOOTER */

footer {
  background-color: #242424;
}

.footer__logo {
  display: flex;
}

.footer__logo--img {
  width: 160px;
  filter: grayscale(1) invert(1);
}

.footer__link,
.footer__copyright {
  color: #fff
}

.footer__list {
  margin: 20px 0 24px 0;
}

.footer__link {
  padding: 0 8px;
}


/*  PLANS PAGE */

#plans__body {
  background-color: rgba(115, 66, 214, 0.1);
  flex: 1;
  display: flex;
  flex-direction: column;
}

#plans__main {
  flex: 1;
  background-color: rgb(248,249,250);
}

.plans__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
}

 .plans__header--title {
  margin-bottom: 0;
 }

 select {
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  color: #606266;
  height: 40px;
  line-height: 40px;
  outline: none;
  padding: 0 16px;
  width: 100%;
  max-width: 200px;
 }

 .plans__loading {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 60px;
  font-size: 60px;
  color: #167cac;
 }

 .fa-spinner {
  animation: rotate 600ms infinite linear;
 }

 @keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
 }

 /* plan/id */

 .container__plan--selected {
  padding-top: 16px;
 }

 .plan__selected {
  display: flex;
 }

 .plan__selected--figure {
  width: 40%;
  padding: 16px 32px;
  display: flex;
  justify-content: center;
  align-items: center;
 }

 .plan__selected--img {
  max-height: 500px;
  width: auto;
  max-width: 100%;
 }

 .plan__selected__description {
  width: 60%;
  padding: 16px 32px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
 }

 .plan__selected--top {
  padding: 0 24px;
  margin-bottom: 8px;
  font-size: 32px;
  color: #242424;
  display: flex;
  align-items: center;
 }

 .plan__selected--link {
  color: #242424;
 }

 .fa-arrow-left {
  font-size: 30px;
 }

 .plan__selected--title--top {
  margin-left: 10px;
  margin-bottom: 0;
 }

 .plan__summary {
  margin-bottom: 16px;
 }

 .plan__selected--title {
  margin-bottom: 8px;
 }

 .plan__summary--title {
  margin-bottom: 8px;
 }

 .plan__summary--para {
  margin-bottom: 16px;
 }

 .plan__price {
  font-size: 16px;
  margin: 8px 0 24px 0;
 }

 .link__breadcrumbs {
  color: #fbfbfb;
  margin-bottom: 16px;
 }


 /* CART */

 .cart__header {
  background-color: #167cac;
  margin: 0 24px;
  padding: 8px 16px;
  color: white;
  display: flex;
 }

 .cart__item {
  margin: 24px;
  display: flex;
  padding-right: 8px;
 }

 .cart__title {
  margin-bottom: 16px;
 }

 .cart__plan {
  width: 60%;
  display: flex;
 }

 .cart__quantity {
  width: 40%;
  display: flex;
  align-items: center;
 }

 .cart__total {
  width: 100%;
  max-width: 100px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 18px;
 }

 .cart__input {
  padding: 8px;
  width: 60px;
 }

 .cart__plan--img {
  width: 100%;
  max-width: 90px;
  max-height: 128px;
 }

 .cart__plan--info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 8px 16px;
 }

 .cart__plan--title {
  font-size: 20px;
  margin-bottom: 16px;
 }

 .cart__plan--price {
  margin-bottom: 16px;
 }

 .cart__plan--remove {
  background-color: transparent;
  color: #cf0000;
  border: none;
  padding-left: 0;
  width: 48px;
 }

 .total {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 0 24px;
  width: 100%;
  max-width: 300px;
  margin-left: auto;
  font-size: 16px;
 }

 .total__item {
  font-size: 18px;
  margin-bottom: 24px;
  width: 100%;
  display: flex;
  justify-content: space-between;
 }

 .total__sub-total {
  border-top: 2px solid #167cac;
  padding-top: 24px;
 }

 .total__price {
  font-weight: bold;
 }

 .cart__empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
 }

 .cart__empty--img {
  padding: 40px;
  max-width: 400px;
 }

 .btn__checkout {
  width: 100%;
  font-size: 16px;
  padding: 12px 1;
 }


 .billing-details input {
  display: block;
  margin-bottom: 10px;
  padding: 10px;
  font-size: 16px;
  width: 100%;
}

#payment-form {
  max-width: 800px;
  width: 600px;
  margin: auto;
}

#payment-form h1 {
text-align: center;
margin-bottom: 10px;
margin-top: 10px;
}

#submit {
  background-color: #4CAF50; 
  color: white;
  padding: 15px 20px;
  margin: 10px 0;
  border: none;
  cursor: pointer;
  width: 100%;
}

#submit:hover {
  opacity: 0.8;
}

 /*Why choose us*/

 .why-choose-us-top {
  background-image: url('./assets/whyus/wusbg.png');
  background-size:cover;
  height: 38vw;
  width: 100%;
  color: #fafafa;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 50px 20px;
  background-color: #242424;
  font-size: 40px;
}

.why-choose-us-top h2 {
  text-align: right;
  padding-right: 50px;
  padding-bottom: 200px;
  font-size: 40px;
}

.why-choose-us-top h1 {
  text-align: left;
  padding-left: 50px;
  padding-top: 40px;
}

.why-choose-us h1 {
  margin-bottom: 30px;
}

.why-choose-us h2 {
  margin-bottom: 25px;
  font-size: 34px;
  letter-spacing: 1px;
}

.why-choose-us .content {
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 10%;
  margin-right: 10%;
}

.why-choose-us section {
  display: flex;
  width: 100%;
  margin-bottom: 40px;
  justify-content: space-between;
  align-items: center;
}

.why-choose-us section div {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 40px;
  align-items: center;
  font-size: 24px;
  letter-spacing: 0.5px;
}

.why-choose-us section:nth-child(0) {
  flex-direction: row;
}

.why-choose-us section:nth-child(even) {
  flex-direction: row-reverse;
}

.why-choose-us section img {
  width: 50%;
  max-width: 600px;
  border-radius: 10px;
}

.why-choose-us section div {
  width: 40%;
  text-align: left;
}


 /* SMALL PHONES, TABLETS, LARGE SMARTPHONES */

 @media (max-width: 768px) {
  .header__description {
    padding: 0 24px;
  }

  h1 {
    font-size: 40px;
  }

  h2 {
    font-size: 26px;
  }

  button {
    font-size: 18px;
  }

  

  .buttonpos button {
    width: 100%;
    padding: 15px;
    font-size: 16px;
  }

  .plan {
    width: 50%;
  }

  .plan__selected {
    flex-direction: column;
  }

  .plan__selected--figure {
    width: 100%;
    justify-content: flex-start;
  }

  .plan__selected__description {
    width: 100%;
  }

  .plans__container {
    flex-direction: column;
    margin: 10px;
    align-items: center;
  }

  .plans__container--cart {
    flex-direction: column;
    margin: 20px;
    align-items: center;
    
  }

  .section__title {
    margin-top: 45px;
  }

  .why-choose-us h1{
    margin-top: -50px;
    margin-bottom: 2px;
  }

  .why-choose-us section {
    flex-direction: column !important; /* Mobil nézetben minden egymás alatt */
  }

  

  .why-choose-us section img,
  .why-choose-us section div {
    width: 100% !important; /* Mobil nézetben teljes szélesség */
    margin: 0 0 20px 0; /* Térköz csak alul */
  }
 }

 /*SMALL PHONES, LARGE SMARTPHONES*/

 @media (max-width: 550px) {
  .btn__menu {
    display: block;
  }

  .nav__list {
    display: none;
  }

  .highlight {
    width: 100%;
  }

  .highlight:not(:last-child) {
    margin-bottom: 24px;
  }

  .cart__plan--img {
    display: none;
  }

  .cart__plan--info {
    padding-left: 0;
  }
 }