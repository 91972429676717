
  

  .header-left, .header-menu, .header-menu-right {
    list-style: none;
    display: flex;
    align-items: center;
    gap: 2rem;
    margin: 0;
    padding: 0;
  }
  
  .header-menu li, .header-menu-right li {
    cursor: pointer;
    position: relative;
  }
  
  .header-menu li:hover::after, .header-menu-right li:hover::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -5px;
    height: 2px;
    width: 100%;
    background-color: black;
  }

  .header-menu li::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -5px;
    height: 2px;
    width: 0; /* Kezdetben a vonal szélessége 0 */
    background-color: black;
    transition: width 0.5s ease; /* Lassítjuk az animációt és a szélességet animáljuk */
  }
  
  .header-menu li:hover::after {
    width: 100%; /* A vonal szélességét 100%-ra állítjuk hover esetén */
  }
  
  
  .logo {
    width: 80px;
    height: auto;
  }
  


  






