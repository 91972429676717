.activity-level-selector {
    text-align: center;
    padding: 20px;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin: 20px auto;
  }
  
  .activity-level-selector h2 {
    margin-bottom: 20px;
    color: #333;
    font-size: 1.5rem;
  }
  
  .activity-level-selector ul {
    list-style: none;
    padding: 0;
  }
  
  .activity-level-selector li {
    background: #f4f4f4;
    margin-bottom: 10px;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, box-shadow 0.3s;
  }
  
  .activity-level-selector li:hover {
    background-color: #e0e0e0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  .activity-level-selector button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    color: white;
    background-color: #9c9c9c; 
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .activity-level-selector button:hover {
    background-color: #464d53; 
  }
  
  .activity-level-selector li.active {
    background-color: #e0e0e0; /* például szürke háttér */
    color: black;
    font-weight: bold;
  }
  