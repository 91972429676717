.user-details-form  {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    gap: 20px !important;
    padding: 20px !important;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 500px;
    margin-top: 80px;
  }
  
  .user-details-form input {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #d9d9d9; 
    font-size: 1rem;
  }
  
  .user-details-form button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    color: white;
    background-color: #167cac; 
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .user-details-form button:hover {
    background-color: #165cac; 
  }
  
  .user-details-form button[type="button"] {
    background-color: #9c9c9c; 
  }
  
  .user-details-form button[type="button"]:hover {
    background-color: #464d53; 
  }
  
  @media screen and (max-width: 768px) {
    .user-details-form {
        width: auto; /* A szélesség automatikusan igazodik a kisebb képernyőkhöz */
        padding: 10px; /* Kisebb padding a mobil nézeten */
        margin: 20px; /* Több tér a form körül */
    }

    .user-details-form input {
        padding: 8px; /* Kisebb padding az input mezőkön */
        font-size: 0.61rem; /* Kis méretű betűtípus a kisebb képernyőkön */
    }

    .user-details-form button {
        padding: 8px 16px; /* Kisebb gombok */
        font-size: 0.9rem; /* Kis méretű betűtípus a gombokon */
    }
}

@media screen and (max-width: 480px) {
    .user-details-form {
        margin-top: 40px; /* Kisebb felső térköz a nagyon kis képernyőkön */
    }

    .user-details-form input,
    .user-details-form button {
        font-size: 0.6em; /* Még kisebb betűtípus a nagyon kis képernyőkön */
    }
}
